*{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.custom-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .slider {
    width: 80%;
  }
  body {
    margin: 0;
    font-family: 'Lilita One', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .valueBox{
    padding: 20px;
    text-align: center;
    letter-spacing: 0.0875rem;
    width: 33.33%;
    min-height: 150px;
  }
  .valueBox img,.valueBox  p{
    margin-bottom: 10px;
  }
  .levelBox {
    padding: 20px;
    text-align: center;
    letter-spacing: 0.0875rem;
    width: 20%;
    min-height: 60px;
  }
  .levelBox p{
    margin-bottom: 10px;
  }
  .social_icon{
    padding: 5px 5px;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.1rem;
  }
  .web3Button-color{
    background-color: #159bd7 !important;
    color: #fff !important;
  }

  @media (max-width: 600px) {
    .valueBox, .levelBox{
      width: 100%;
    }
  }